<template>
  <div class="page-content">
    <page-breadcrumb title="Users" class="mb-1" />
    <b-card>
      <div class="d-flex justify-content-start align-items-center">
        <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
      </div>
      <data-table-ssr id="user_table" ref="user_table" :limit-base="9" pagination
        :columns="user_table_fields" :get-list-fn="getList"
        :to-edit-fn="toDetail"
      />
    </b-card>
  </div>
</template>

<script>
import service from "../service";
const user_table_fields = [
  { label: 'Avatar', field: 'avatar', input_type: 'avatar', width: '100px', },
  { label: 'Name', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Phone', field: 'phone' },
  { label: 'Language', field: 'language', width: '130px', },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
];

const filter_schema = [
  { cols: 2, fields: [{ label: 'Name', field: 'name' }] },
  { cols: 2, fields: [{ label: 'Email', field: 'email', input_type: 'email' }] },
  { cols: 2, fields: [{ label: 'Phone', field: 'phone' }] },
];

export default {
  data() {
    return {
      user_table_fields,
      filter_schema,
      filter: { name: null, email: null, phone: null }
    };
  },
  watch: {
    filter: {
      handler() {
        this.$refs.user_table.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList({ limit, page, query, searchTerm }) {
      let search_query = {};
      let query_filter = [{ email: { $ne: null }}];
      if (this.filter.name) {
        query_filter.push({ name: { $regex: this.filter.name }});
      }
      if (this.filter.email) {
        query_filter.push({ email: { $regex: this.filter.email }});
      }
      if (this.filter.phone) {
        query_filter.push({ phone: { $regex: this.filter.phone }});
      }
      if(query_filter.length) { search_query['$and'] = query_filter }

      let list = [], total = 0;
      let response = await service.getList({ query: { ...query, ...search_query }, page, limit });
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total };
    },
    toDetail(row) {
      this.$router.push({ name: 'user-detail', params: { user_id: row._id } });
    },
  },
};
</script>
